import React, { useEffect, useRef, useState } from 'react';

import { graphql, Link } from 'gatsby';
import classNames from 'classnames';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../components/layout.jsx';
import CareersBlock from '../components/careers-block.jsx';
import Section from '../components/section.jsx';
import TitleBlock from '../components/title-block.jsx';
import Testimonials from '../components/testimonials.jsx';
import Hero from '../components/hero.jsx';
import { ChevronDownIcon } from '@heroicons/react/solid';
import Button from '../components/button.jsx';
import Title from '../components/title.jsx';
import RichContext from '../components/rich-content.jsx';

export default function Careers(props) {
	let { data } = props;
	let { sanityCareersPage } = data;
	let { title, team, seo } = sanityCareersPage;

	return (
		<Layout seo={seo}>
			<Hero>
				<div className="grid gap-12 xl:grid-cols-2">
					<div className="grid grid-rows-[max-content,max-content] self-y-center gap-8 xl:max-w-3xl">
						<h1 className="text-4xl md:text-5xl 2xl:text-6xl self-y-center">
							<TitleBlock title={title} />
						</h1>

						<Link
							to="#careers"
							className="flex items-center justify-center mt-12 xl:justify-start xl:mt-0 xl:w-max text-primary"
						>
							<span className="text-sm uppercase md:text-base 2xl:text-lg">Apply now</span>
							<ChevronDownIcon className="w-6 h-6 ml-2 transition-transform duration-300 transform text-primary" />
						</Link>
					</div>

					<div className="relative w-full rounded-full lg:h-full lg:w-auto lg:max-w-none">
						<StaticImage src="../images/ship-dock.svg" alt="Ship with docking station" />
					</div>
				</div>
			</Hero>

			<Section id="careers">
				<CareersBlock />
			</Section>

			<Section>
				<Title>{team.title}</Title>
				<div>
					<div className="max-w-3xl mt-8 text-xl">
						<RichContext blocks={team._rawDescription} />
					</div>
					<span className="inline-block mt-6">
						<Button as="link" to="./../team">
							Meet the team
						</Button>
					</span>
				</div>
			</Section>

			<div className="mx-auto">
				<ImageCarousel images={team.images || []} />
			</div>
		</Layout>
	);
}

function ImageCarousel(props) {
	let { images } = props;
	let index = useRef(0);
	let [autoRotate, setAutoRotate] = useState(true);

	let [activeImage, setActiveImage] = useState(function () {
		if (images.length) {
			return images[index.current];
		}
	});

	useEffect(() => {
		if (!autoRotate) return;

		let interval = setInterval(function () {
			let newIndex = index.current + 1;
			index.current = newIndex;

			setActiveImage(images[newIndex % images.length]);
		}, 5000);

		return () => {
			clearInterval(interval);
		};
	}, [autoRotate, images, index]);

	function handleDotClick(image) {
		setAutoRotate(false);
		setActiveImage(image);
	}

	if (images.length === 0) {
		return null;
	}

	let renderDots = [];
	if (images.length > 1) {
		for (let image of images) {
			let className = classNames(
				'w-2.5 h-2.5 border rounded-full border-primary transition duration-300 focus:outline-none',
				{
					'bg-primary': image.asset.url === activeImage.asset.url,
					'bg-transparent': image.asset.url !== activeImage.asset.url,
				},
			);

			renderDots.push(
				<button key={image.asset.url} onClick={() => handleDotClick(image)} className={className}></button>,
			);
		}
	}

	return (
		<section className="flex flex-col items-center justify-center px-8 py-12">
			<img src={activeImage?.asset?.url+"?h=500"} className="object-contain max-h-[32rem]" alt="Brainjar team" />
			<div className="flex items-center justify-center mt-8 space-x-2 sm:mt-6">{renderDots}</div>
		</section>
	);
}

export const query = graphql`
	query CareersPage($language: String!) {
		sanityCareersPage(lang: { eq: $language }) {
			title {
				children {
					_key
					text
					marks
				}
			}
			team {
				title
				_rawDescription
				images {
					asset {
						url
					}
				}
			}
			seo {
				description
				image {
					asset {
						url
					}
				}
			}
		}
	}
`;
