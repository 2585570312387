import React from 'react';

import { graphql, Link, useStaticQuery } from 'gatsby';

import Section from './section.jsx';

import useTranslate from '../hooks/use-translate.js';
import Title from './title.jsx';
import SubTitle from './sub-title.jsx';
import { ChevronRightIcon } from '@heroicons/react/solid';

export default function CareersBlock() {
	let t = useTranslate('careers');
	let data = useStaticQuery(query);
	let { allCareer, sanityCareersPage } = data;
	let { nodes: careers } = allCareer;
	let { images } = sanityCareersPage;

	let renderCareers = [];
	for (let career of careers) {
		renderCareers.push(
			<li key={career.id}>
				<Link
					to={`/careers/${career.slug}`}
					target="_blank"
					rel="noreferrer"
					className="inline-flex items-center text-2xl group"
				>
					<ChevronRightIcon className="w-6 h-6 transition-transform transform md:group-hover:translate-x-2" />
					<span className="ml-4">{career.title}</span>
				</Link>
			</li>,
		);
	}

	return (
		<Section id="careers">
			<div className="">
				<Title>{t('title')}</Title>
				<SubTitle>{t('subtitle')}</SubTitle>
				<ul className="mt-8 space-y-4">{renderCareers}</ul>
				<div className="hidden lg:mt-16 lg:grid lg:grid-cols-3 lg:gap-6 2xl:gap-12">
					{images.map((image) => (
						<img key={image._key} src={image.asset.url+"?h=450"} className="object-contain" />
					))}
				</div>
			</div>
		</Section>
	);
}

const query = graphql`
	query Careers {
		allCareer {
			nodes {
				id
				title
				slug
			}
		}
		sanityCareersPage(lang: { eq: "en" }) {
			images {
				_key
				asset {
					url
				}
			}
		}
	}
`;
